import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { setMessage } from './message';

import ManagerService from '../../services/manager.service';


export const login = createAsyncThunk(
  "auth/login/",
  async ({ email, password }, thunkAPI) => {
    try {
      const payload = { email, password }
      const response = await ManagerService.login(payload);
      thunkAPI.dispatch(memberSlice.actions.setAuthStatus(response.data))
      thunkAPI.dispatch(setMessage({message: response.message, status: 'True'}))
      return response;
    } catch (error) {
        console.log(error)
        const message =
        error.message ||
        error.toString();
        thunkAPI.dispatch(setMessage({message: message, status: 'False' }));
      return error;
      return thunkAPI.rejectWithValue();
    }
  }
);

export const logout = createAsyncThunk(
  "auth/logout",  
  async (_, thunkAPI) => {
    thunkAPI.dispatch(memberSlice.actions.signOut())
    return true
  }
);

// Get user profile details
export const getProfile = createAsyncThunk(
  "auth/user/",
  async(thunkAPI) => {
    try {
      const response = await ManagerService.getProfile();
      // console.log('Profile', response[0])
      // userSlice.actions.setUser(response[0])
      return response[0];
    } catch (error) {
      console.log(error)
      return error
    }
  }
)


// Get user wallets data
export const getWallets = createAsyncThunk(
  "/wallet_details/",
  async(thunkAPI) => {
    try {
      const response = await ManagerService.walletDetails();
      // console.log('wallet details', response)
      return response.data;
    } catch (error) {
      console.log(error)
      return error
    }
  }
)



/**  MANAGERS  */
export const addUser = createAsyncThunk(
  '/managers/',
  async(payload, thunkAPI) => {
    try {
        const response = await ManagerService.createUser(payload)
        thunkAPI.dispatch(setMessage({message: response.message, status: 'True'}))
        return response
      } catch (error) {
        console.log(error)
        thunkAPI.dispatch(setMessage({message: error.message, status: 'False'}))
        return thunkAPI.rejectWithValue()
    }
  }
)

export const getMembers = createAsyncThunk(
  '/managers/',
  async(thunkAPI) => {
    try {
        const response = await ManagerService.getUsers()
        return response
      } catch (error) {
        return thunkAPI.rejectWithValue()
    }
  }
)

export const deleteMember = createAsyncThunk(
  '/manage_team/',
  async(member_id, thunkAPI) => {
    try {
        const response = await ManagerService.deleteUser(member_id)
        thunkAPI.dispatch(setMessage({message: response?.message, status: 'True' }))
        return response
      } catch (error) {
        thunkAPI.dispatch(setMessage({message: error?.message, status: 'False' }))
        return thunkAPI.rejectWithValue()
    }
  }
)

export const manageMember = createAsyncThunk(
  '/manage_team/',
  async(payload, thunkAPI) => {
    try {
        const response = await ManagerService.manageUser(payload)
        thunkAPI.dispatch(setMessage({message: response?.message, status: 'True' }))
        return response
      } catch (error) {
        thunkAPI.dispatch(setMessage({message: error?.message, status: 'False' }))
        return thunkAPI.rejectWithValue()
    }
  }
)

/*** Marketing */

export const getCustomersList = createAsyncThunk(
  '/customers_list/',
  async(thunkAPI) => {
    try {
        const response = await ManagerService.getCustomers()
        return response
      } catch (error) {
        return thunkAPI.rejectWithValue()
    }
  }
)

export const getSmsList = createAsyncThunk(
  '/customer_sms/',
  async(thunkAPI) => {
    try {
        const response = await ManagerService.getSms()
        return response
      } catch (error) {
        return thunkAPI.rejectWithValue()
    }
  }
)

export const getEmailList = createAsyncThunk(
  '/customer_email/',
  async(thunkAPI) => {
    try {
        const response = await ManagerService.getEmails()
        return response
      } catch (error) {
        return thunkAPI.rejectWithValue()
    }
  }
)

export const deleteSmsById = createAsyncThunk(
  '/custmer_sms/',
  async(id, thunkAPI) => {
    try {
        const response = await ManagerService.deleteSms(id)
        thunkAPI.dispatch(setMessage({message: response?.message, status: 'True' }))
        return response
      } catch (error) {
        thunkAPI.dispatch(setMessage({message: error?.message, status: 'False' }))
        return thunkAPI.rejectWithValue()
    }
  }
)

export const deleteCustomerById = createAsyncThunk(
  '/custmer_sms/',
  async(id, thunkAPI) => {
    try {
        const response = await ManagerService.deleteCustomer(id)
        thunkAPI.dispatch(setMessage({message: response?.message, status: 'True' }))
        return response
      } catch (error) {
        thunkAPI.dispatch(setMessage({message: error?.message, status: 'False' }))
        return thunkAPI.rejectWithValue()
    }
  }
)

export const uploadProfilePhoto = createAsyncThunk(
  '/profile_photo/',
  async(payload, thunkAPI) => {
    try {
        const response = await ManagerService.updateProfilePhoto(payload)
        thunkAPI.dispatch(setMessage({message: response.message, status: 'True'}))
        return response
      } catch (error) {
        console.log(error)
        thunkAPI.dispatch(setMessage({message: error.message, status: 'False'}))
        return thunkAPI.rejectWithValue()
    }
  }
)

export const verifyWalletPin = createAsyncThunk(
  '/social_media/',
  async(payload, thunkAPI) => {
    try {
        const response = await ManagerService.pinVerification(payload)
        return response
      } catch (error) {
        console.log(error)
        return error
    }
  }
)

export const changeAccountPassword = createAsyncThunk(
  '/change_password/',
  async(payload, thunkAPI) => {
    try {
        const response = await ManagerService.changePassword(payload)
        return response
      } catch (error) {
        console.log(error)
        return error
    }
  }
)

/** Notifications */
export const getMemberNotifications = createAsyncThunk(
  '/notification/',
  async(option, thunkAPI) => {
    try {
        const response = await ManagerService.getNotifications(option)
        return response
      } catch (error) {
        console.log(error)
        return error
    }
  }
)

/********    TRANSACTIONS  */
export const getTransactionHistory = createAsyncThunk(
  '/transaction_history/',
  async(payload, thunkAPI) => {
    try {
      const response = await ManagerService.transactionHistory(payload)
      // console.log("transactions", response)
      return response
    }
    catch (error){ 
      thunkAPI.dispatch(setMessage({message: error.message, status: 'False'}))
      return error
    }
})

export const getVouchers = createAsyncThunk(
  '/members/vouchers/',
  async(payload, thunkAPI) => {
    try {
      const response = await ManagerService.getPaycodes(payload)
      return response
    }
    catch (error){ 
      thunkAPI.dispatch(setMessage({message: error.message, status: 'False'}))
      return error
    }
})

export const applyPayCode = createAsyncThunk(
  '/members/vouchers/',
  async(payload, thunkAPI) => {
      try {
          const response = await ManagerService.claimPaycode(payload)
          thunkAPI.dispatch(setMessage({message: response.message, status: 'True'}))
          return response
      } catch (error) {
        thunkAPI.dispatch(setMessage({message: error.message, status: 'False'}))
        return error
      }
  }
)

export const markAsReadItem = createAsyncThunk(
  '/member_notification/',
  async(id, thunkAPI) => {
    try {
        const response = await ManagerService.readNotifications(id)
        return response
      } catch (error) {
        console.log(error)
        return error
    }
  }
)

export const getAccounts = createAsyncThunk(
  "/members/virtual_account/",
  async(thunkAPI) => {
    try {
      const response = await ManagerService.getVirtualAccounts();
      // console.log("accounts", response)
      return response.data;
    } catch (error) {
      return error;
    }
  }
)

// Get user business data
export const getBusiness = createAsyncThunk(
  "/merchants/",
  async(thunkAPI) => {
    try {
      const response = await ManagerService.getStore();
      return response;
    } catch (error) {
      console.log(error)
      return error
    }
  }
)

// Get user business data
export const createLoyaltyVoucher = createAsyncThunk(
  "/loyalty_voucher/",
  async(payload, thunkAPI) => {
    try {
      const response = await ManagerService.createLoyaltyVoucher(payload);
      return response;
    } catch (error) {
      console.log(error)
      return error
    }
  }
)

const memberSlice = createSlice({
  name: "user",
  initialState: {
    is_store_created: false,
    business: null,
    isManager: false,
  },
  reducers: {
    hasError(state, action) {
      state.isLoading = false
      state.error = action.payload
    },
    startLoading(state) {
      state.isLoading = true
    },
    setUser(state, action) {
      state.isLoading = false
      state.user = action.payload
    },
    setToken(state, action) {
      localStorage.setItem("token", action.payload); 
    },
    signOut(state) {
      state.isLoggedIn = false
      // state.user = null,
      state.isManager = false
      localStorage.removeItem("token")
    },
    setBusiness(state, data) {
      state.business = data
    }
  },
  extraReducers: {
    [getProfile.fulfilled]: (state, action) => {
      state.is_store_created = action.is_store_created
      if(action.payload.id) {
        state.user = action.payload
      }
    },
    [getProfile.rejected]: (state, action) => {
      state.is_store_created = false
    },
    [getBusiness.fulfilled]: (state, action) => {
      state.business = action.payload
    },
  }
})

const { reducer } = memberSlice
export default reducer;